import React, { useState } from 'react';
import Reveal from 'react-reveal';
import Layout from "../components/layouts";
import { Parallax } from 'react-parallax';
import header from "../images/header-energy-playground-aqmd-bg.jpg";
import logo from "../images/header-energy-playground-aqmd-logo.png";
import logo2 from "../images/header-energy-playground-aqmd-logo-2.png";
import image1 from "../images/energy-playground-aqmd-1.png";
import image2 from "../images/energy-playground-aqmd-2.png";
import image3 from "../images/energy-playground-aqmd-3.png";
import image4 from "../images/energy-playground-aqmd-4.png";
import appstore from "../images/logo-apple-store.png";
import playstore from "../images/logo-google-store.png";
import logoGi from "../images/logo-gi-black.png";
import logoAqmd from "../images/logo-aqmd.png";
import { Helmet } from "react-helmet";
import Slider from 'react-slick';
import slider1 from "../images/energy-playground-aqmd-slider1.jpeg";
import slider2 from "../images/energy-playground-aqmd-slider2.jpeg";
import slider3 from "../images/energy-playground-aqmd-slider3.jpeg";
import slider4 from "../images/energy-playground-aqmd-slider4.jpeg";
import slider5 from "../images/energy-playground-aqmd-slider5.jpeg";
import slider6 from "../images/energy-playground-aqmd-slider6.jpeg";
import slider7 from "../images/energy-playground-aqmd-slider7.jpeg";

const EnergyPlaygroundAQMD = () => {

  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState({
    message: "",
    type: null,
  }); 
  const messageClasses = `contact-message ${feedback.type}`;

  const gallerySettings = {
    infinite: true,
    dots: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
          breakpoint: 600,
          settings: {
              dots: false
          }
      },
  ]
  };

  const handleSubmit = ((e) => {
    e.preventDefault();
    const formData  = new FormData();

    for(const name in e) {
      formData.append(name, e[name]);
    }

    if (fName !== "" && lName !== "" && email !== "") {
        let regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

        if (!regex.test(email)) {
          setFeedback({
            message: 'Please enter a valid E-mail',
            type: 'error'
          });
          return;
        } else {
          fetch("https://globalinheritance.us2.list-manage.com/subscribe/post-json?&c=?", {
            method: "POST",
            body: JSON.stringify(formData),
            mode: "no-cors",
          }).then(() => {
            setFeedback({
              message: 'Thank you! Your information has been sent',
              type: 'success'
            });
          }).catch((error) => {
            setFeedback({
              message: error.message,
              type: "error",
            });
          });
        }
    } else {
      setFeedback({
        message: 'Please fill out all fields',
        type: 'error'
      });
    }
  });

  return (
    <Layout navBg>
       <Helmet>
        <meta charSet="utf-8" />
        <title>Global Inheritance | Energy Playground x SouthCoastAQMD</title>
      </Helmet>
    <div className="carpoolchella-page">
      <Parallax className="page-header" bgImage={header} strength={100}>
          <div className="aqmd-header">
            <h1 className="page-header-title white"></h1>
            <div className="aqmd">
              <img src={logo} style={{width: "80%"}}/>
              <a href="https://www.aqmd.gov/" target="_blank" rel="noreferrer noopener"><img src={logo2} style={{width: "100%"}}/></a>
            </div>
            
            
          </div>
      </Parallax>
        
      
        <Reveal effect="animated fadeIn">

        <div className="carpoolchella-inner">
        
          <div className="aqmd-description half">
          <img src={image2} style={{ maxWidth: 500, width: "100%"}} />
            <div style={{marginTop: 20, marginBottom: 20, boxShadow: '0px 0px 24px 15px #ddf1eaa3' }}>
            <Slider {...gallerySettings}>
              <div style={{ width: "100%", height: 500, position: "relative"}}>
                <img src={slider1} style={{ width: "100%", height: 400, objectFit: "cover", objectPosition: "center"}} />
              </div>
              <div style={{ width: "100%", height: 500, position: "relative"}}>
                <img src={slider2} style={{ width: "100%", height: 400, objectFit: "cover", objectPosition: "center"}} />
              </div>
              <div style={{ width: "100%", height: 500, position: "relative"}}>
                <img src={slider3} style={{ width: "100%", height: 400, objectFit: "cover", objectPosition: "center"}} />
              </div>
              <div style={{ width: "100%", height: 500, position: "relative"}}>
                <img src={slider4} style={{ width: "100%", height: 400, objectFit: "cover", objectPosition: "center"}} />
              </div>
              <div style={{ width: "100%", height: 500, position: "relative"}}>
                <img src={slider5} style={{ width: "100%", height: 400, objectFit: "cover", objectPosition: "center"}} />
              </div>
              <div style={{ width: "100%", height: 500, position: "relative"}}>
                <img src={slider6} style={{ width: "100%", height: 400, objectFit: "cover", objectPosition: "center"}} />
              </div>
              <div style={{ width: "100%", height: 500, position: "relative"}}>
                <img src={slider7} style={{ width: "100%", height: 400, objectFit: "cover", objectPosition: "center"}} />
              </div>

              
            </Slider>

            </div>
            <a style={{ display: 'block', width: 320, marginTop: 50, left: "50%", transform: "translateX(-50%)" }} href="https://www.flickr.com/photos/74696088@N00/albums/72177720316427683/" target="_blank" rel="noopener noreferrer">
              <div className="partner-cta" >
                <div className="btn primary small solid" style={{ height: 48, fontSize: 18 }}>
                    <span>View Coachella 2024 Album Here</span>
                </div>
              </div>
            </a>
            <a style={{ display: 'block', width: 320, marginTop: 25, left: "50%", transform: "translateX(-50%)" }} href="https://www.flickr.com/photos/74696088@N00/albums/72177720316673425" target="_blank" rel="noopener noreferrer">
              <div className="partner-cta" >
                <div className="btn primary small solid" style={{ height: 48, fontSize: 18 }}>
                    <span>View Stagecoach 2024 Album Here</span>
                </div>
              </div>
            </a>
          </div>
          <div className="aqmd-description half">
          <h1>Clean Air Festival & Life Tips</h1>
              <ul className="number">
                  <li>
                    <h3>Use Reusable Water Bottles</h3>
                    <p>Plastics are usually made from oil, which requires a lot of energy for manufacturing plus storage & shipping.</p>
                  </li>
                  <li>
                    <h3>Always Recycle</h3>
                    <p>
                      Recycling generally takes less energy than creating new products which reduces air pollution & greenhouse gases.
                    </p>
                  </li>
                  <li>
                    <h3>Use Your Power</h3>
                    <p>Charge your devices at the Energy Playground. Kinetic and renewable energy minimize air pollution.</p>
                  </li>
                  <li>
                    <h3>Get Inspired</h3>
                    <p>
                      Visit Design Studio to let your creativity show your support for the planet and clean air.
                      Keep up on the latest AQMD <a href="https://www.aqmd.gov/home/programs/community" target="_blank" rel="noreferrer noopener">programs and events.</a>
                    </p>
                  </li>
                  <li>
                    <h3>Minimize Food Waste</h3>
                    <p>
                      Food that goes into landfills rots and produces methane gas, which is a GHG and creates unpopular odors.
                    </p>
                  </li>
                  <li>
                    <h3>Breathe Easy</h3>
                    <p>
                      Get quick alerts for dust storms, wildfires, or unsafe <a href="https://www.aqmd.gov/home/air-quality" target="_blank" rel="noreferrer noopener">air quality</a> on the free <a href="http://www.aqmd.gov/mobileapp" target="_blank" rel="noreferrer noopener">AQMD app</a>.
                    </p>
                  </li>
              </ul>
                <div style={{ position: 'relative', width: "100%", maxWidth: 400, margin: '100px auto 0' }}>
                  <h3 className="bolton"><a href="http://www.aqmd.gov/mobileapp" target="_blank" rel="noreferrer noopener">Download South Coast AQMD's Free App</a></h3>
                  <a href="https://apps.apple.com/us/app/south-coast-aqmd/id358859718" target="_blank" rel="noopener noreferrer" style={{ position: 'absolute', width: 200, top: '50%', marginTop: -75, left: '50%', transform: "translateX(-37%)" }}><img src={appstore} style={{ width: "100%" }} /></a>
                  <a href="https://play.google.com/store/apps/details?id=gov.aqmd.AQMD&hl=en_US&gl=US" target="_blank" rel="noopener noreferrer" style={{ position: 'absolute', width: 200, top: '50%', marginTop: 0, left: '50%', transform: "translateX(-37%)" }}><img src={playstore} style={{ width: "100%" }} /></a>
                  <img src={image4} style={{width: "100%"}} />
                </div>
            
          </div>
        </div>
        </Reveal>
        <Reveal effect="animated fadeIn">
          <div className="carpoolchella-inner">
          <div className="aqmd-description half right">
          <img src={image1} className="charge-image"/>

          </div>
          <div className="aqmd-description half">
              <div style={{ display: "flex" }}>
                <div  style={{ maxWidth: 200, width: "100%"}}>
                <img src={image3} style={{ width: "100%"}} />
                </div>
                <div style={{ display: "flex", flexFlow: "column", marginLeft: 20}}>
                  <p className="bolton">Presented by:</p>
                  <img src={logoGi}  style={{ maxWidth: 100, width: "100%", marginBottom: 20}} />
                  <a href="https://www.aqmd.gov/" target="_blank" rel="noreferrer noopener"><img src={logoAqmd} style={{ maxWidth: 100, width: "100%"}} /></a>
              </div>
              </div>

            </div>
          </div>
        </Reveal>
        <p style={{ textAlign: "center"}}>*Courtesy of Global Inheritance and Coachella</p>

        <h1 className="bolton" style={{ marginTop: 50, textAlign: 'center'}}>Follow South Coast AQMD</h1>
        <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
            <div style={{ width: 50, height: 50, marginRight: 10 }}><a href="https://urldefense.com/v3/__https:/www.instagram.com/southcoastaqmd/?hl=en__;!!JzNmQwiJ!NGsbxcv1-lG5FTbOiHbXVZnPKVJqvnOjWcgtEAn6XmXxmVVJ2aqNEE3nS-7I0GuCRJoQsTdKyZp-lIPl5F53nDVUnMFD$" target="_blank"><i className="fa fa-instagram" style={{fontSize: 46 }} aria-hidden="true"></i></a></div>
            <div style={{ width: 50, height: 50, marginTop: 2, marginRight: 10 }} className="nav-item"><a href="https://urldefense.com/v3/__https:/twitter.com/SouthCoastAQMD__;!!JzNmQwiJ!NGsbxcv1-lG5FTbOiHbXVZnPKVJqvnOjWcgtEAn6XmXxmVVJ2aqNEE3nS-7I0GuCRJoQsTdKyZp-lIPl5F53nAu3RuMr$" target="_blank"><i className="fa fa-twitter" style={{fontSize: 46 }} aria-hidden="true"></i></a></div>
            <div style={{ width: 50, height: 50, marginTop: 2, marginRight: 10 }} className="nav-item"><a href="http://www.youtube.com/southcoastaqmd" target="_blank"><i className="fa fa-youtube-play" style={{fontSize: 46 }} aria-hidden="true"></i></a></div>
            <div style={{ width: 50, height: 50, marginTop: 2 }} className="nav-item"><a href="https://m.facebook.com/southcoastaqmd" target="_blank"><i className="fa fa-facebook" style={{fontSize: 46 }} aria-hidden="true"></i></a></div>
        </div>
    </div>
    </Layout>
);
}

export default EnergyPlaygroundAQMD;


